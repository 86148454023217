<template>
  <div class="block">
      <div class="block-items-holder">
        <div class="block-item-holder">
            <div class="block-bar" v-if="obj.root != true">
            </div>
            <div class="block-item" v-on:click="$emit('show', {obj: obj, parent: parent})">
                <span>{{ obj.label }}</span>
            </div>
        </div>
      </div>
    <div class="block-holder">
        <Hierarchy v-for="(child, id) in obj.children" v-bind:key="id" v-bind:obj="child" v-bind:parent="obj" v-on:show="$emit('show', $event)" v-on:add="$emit('add', $event)"/>
        <div class="block">
            <div class="block-items-holder">
                <div class="block-item-holder fixed">
                    <div class="block-bar">
                    </div>
                    <div class="block-item fixed" v-on:click="$emit('add', obj)">
                        <span>+</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Hierarchy from './Hierarchy.vue'

export default {
  name: 'Hierarchy',
  props: {
    obj: Object,
    parent: Object
  },
  components: {
      Hierarchy
  },
  data: function() {
    return {}
  },
  methods: {
    
  },
  mounted: function() {
    
  },
  watch: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

.block {
    flex: 1 1 auto;
}

.block-bar {
    background-color: #02A1B6;
    width: 5px;
    height: 10px;
    margin: 0 auto;
}

.block-items-holder {
    display: flex;
    justify-content: stretch;
}

.block-item-holder {
    flex: 1 1 auto;

    &.fixed {
        flex: 0 0 auto;
        margin-left: 5px;
    }
}

.block-item {

    background-color: #02A1B6;
    color: white;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    flex: 1 1 auto;
    

    &:hover {
        background-color: #028192;
    }
    
    &.fixed {
        flex: 0 0 auto;
        background-color: #6FE4EB;
        color: #028192;

        &:hover {
            background-color: #40C9D6;
        }
    }
}

.block-holder {
    display: flex;
    gap: 10px;
}

</style>
