<template>
  <div id="app">
    <ul class="menu">
      <li v-for="(item, id) in items" v-bind:key="id" v-bind:class="{active: tab == item.name}" v-on:click="tab = item.name; obj = null; objParent = null; objParentParent = null;">{{ item.label }}</li>
      <li><input type="file" id="file_input" v-on:change="handleFile"/></li>
      <li><button v-on:click="saveData">Save data structure</button></li>
    </ul>
    <div class="content" v-if="obj == null">
      
      <div v-if="tab == 'hierarchy'" class="block">
        <div class="block-holder">
          <Hierarchy v-bind:obj="root" v-on:show="show" v-on:add="add"/>
        </div>
      </div>
      <RadialTree v-if="tab == 'radial'" v-bind:root="root"/>
      
    </div>
    <div class="table" v-if="obj != null">
      <div class="buttons">
        <div class="button" v-if="objParentParent != null" v-on:click="obj = objParent; objParent = objParentParent;  objParentParent = null;"><i class="fas fa-chevron-left icon"></i>Back</div>
        <div class="button" v-on:click="obj = null; objParent = null;  objParentParent = null;"><i class="fas fa-times icon"></i>Close</div>
      </div>
      <div class="column">
        <h1>Metadata record</h1>
        <table>
          <tr>
            <th><h1>Label</h1></th>
            <td><input class="h1" v-model="obj.label" type="text" v-on:blur="saveCurrent"/></td>
          </tr>
          <tr>
            <th><h2>Name</h2></th>
            <td><input class="h2" v-model="obj.name" type="text" v-on:blur="saveCurrent"/></td>
          </tr>
          <tr>
            <th><h3>Description</h3></th>
            <td><input class="h3" v-model="obj.description" type="text" v-on:blur="saveCurrent"/></td>
          </tr>
          <tr>
            <th><h3>Default data location</h3><p>Currently available: s3_dev and rds_dev</p></th>
            <td><input class="h3" v-model="obj.default_location" type="text" v-on:blur="saveCurrent"/></td>
          </tr>
          <tr v-if="obj.datasets == true || obj.datasets == false">
            <th><h3>Attach datasets to this record?</h3></th>
            <td><input class="h3" v-model="obj.datasets" type="checkbox" v-on:change="saveCurrent"/></td>
          </tr>
          <tr>
            <td colspan="2"><button class="delete" v-on:click="deleteEntry">Delete '{{ obj.label }}' metadata record</button></td>
          </tr>
        </table>

      </div>
      <div class="column">
        <h1>Metadata requirements</h1>
        <div v-for="(label, key) in metadata" v-bind:key="key">
          <h2>{{ label }}</h2>
          <table class="dataset">
            <tr>
              <th></th>
              <th>Label</th>
              <th>Name</th>
              <th>Description</th>
              <th>Attribute type</th>
              <th>Controlled list</th>
            </tr>
            <tr v-for="(metadata, id) in obj.metadata[key]" v-bind:key="id">
              <td><button class="delete" v-on:click="deleteMetadata(key, id)">Delete</button></td>
              <td><input type="text" v-model="metadata.label" v-on:blur="saveCurrent" /></td>
              <td><input type="text" v-model="metadata.name" v-on:blur="saveCurrent" /></td>
              <td><input type="text" v-model="metadata.description" v-on:blur="saveCurrent" /></td>
              <td>
                <select v-model="metadata.attribute_type" v-on:change="saveCurrent">
                    <option value=""></option>
                    <option value="string">String</option>
                    <option value="number">Number</option>
                    <option value="integer">Integer</option>
                    <option value="datetime">Date and time</option>
                    <option value="boolean">True or false</option>
                  </select>
              </td>
              <td>
                <button v-if="metadata.options == undefined" v-on:click="$set(metadata, 'options', []); saveCurrent();">Restrict to a controlled list</button>
                <table v-if="metadata.options != undefined">
                  <tr>
                    <th>Name</th>
                    <th>Label</th>
                    <th></th>
                  </tr>
                  <tr v-for="(option, id2) in metadata.options" v-bind:key="id2">
                    <td><input type="text" v-model="option.name" v-on:blur="saveCurrent" /></td>
                    <td><input type="text" v-model="option.label" v-on:blur="saveCurrent" /></td>
                    <td><button class="delete" v-on:click="metadata.options.splice(id2, 1); if (metadata.options.length == 0) { delete metadata.options }; saveCurrent();">Delete option</button></td>
                  </tr>
                  <tr>
                    <td colspan="3"><button v-on:click="metadata.options.push({label: '', name: ''}); saveCurrent()">Add option</button></td>
                  </tr>
                </table>
              </td>
              
            </tr>
            <tr>
              <td colspan="6"><button v-on:click="addMetadata(key)">Add metadata requirement</button></td>
            </tr>
          </table>
        </div>

        <h2>Required metadata layers</h2>
        <div class="metadata_layer" v-for="(layer, id) in obj.metadata.layers" v-bind:key="id" v-on:click="objParentParent = objParent; objParent = obj; obj = layer; objParentType = 'metadata';"><i class="icon fas fa-layer-group"></i>{{ layer.label }}</div>
        <button v-on:click="addMetadataLayer">Add metadata layer</button>
      </div>
      <div class="column" v-if="obj.datasets != false && obj.datasets != undefined">
        <h1>Associated datasets</h1>
        <div>
          <table class="dataset">
            <tr>
              <th>Label</th>
              <th>Name</th>
              <th>Description</th>
              <th>Dataset type</th>
              <th></th>
            </tr>
            <tr v-for="(dataset, id) in obj.datasets" v-bind:key="id" >
              <td><input type="text" v-model="dataset.label" v-on:blur="saveCurrent" /></td>
              <td><input type="text" v-model="dataset.name" v-on:blur="saveCurrent"/></td>
              <td><input type="text" v-model="dataset.description" v-on:blur="saveCurrent"/></td>
              <td>
                <select v-model="dataset.dataset_type" v-on:change="saveCurrent">
                  <option value=""></option>
                  <option value="tabular">Tabular</option>
                  <option value="spatial_vector">Spatial vector</option>
                  <option value="spatial_raster">Spatial raster</option>
                  <option value="spatial_timeseries">Spatial raster timeseries</option>
                  <option value="tabular_timeseries">Tabular timeseries</option>
                </select>
              </td>
              <td><button class="delete" v-on:click="deleteDataset(id)">Delete</button></td>
            </tr>
            <tr>
              <td colspan="4"><button v-on:click="addDataset">Add dataset to {{ obj.label }}</button></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RadialTree from './components/RadialTree.vue'
import Hierarchy from './components/Hierarchy.vue'

export default {
  name: 'App',
  components: {
    RadialTree,
    Hierarchy
  },
  data: function() {
    return {
      items: [{
        name: "hierarchy",
        label: "Hierarchy view"
      }, {
        name: "radial",
        label: "Radial view"
      }],
      tab: "hierarchy",
      root: {
        'name': 'nafra2',
        'label': 'NaFRA2',
        'info': 'Root node',
        'children': [],
        'root': true
      },
      metadata: {
        dataset: "Dataset-level metadata requirements",
        record: "Record-level metadata requirements"
      },
      obj: null,
      objParent: null,
      objParentParent: null,
      objParentType: null
      
    }

  },
  mounted: function() {

    /*

    {
      name: "property_thresholds",
      label: "Property thresholds",
      description: "Property threshold data",
      datasets: true,
      metadata: {
        record: [],
        dataset: [],
        layers: []
      },
      children: []
    }

    */

   
    
    var root = {
      name: "nafra2",
      label: "NaFRA2",
      description: "tst",
      datasets: false,
      children: [{
        name: "input_data",
        label: "Input data",
        description: "This entry contains all input datasets",
        datasets: false,
        children: [{
          name: 'model_outputs',
          label: "Model outputs",
          description: 'Local and national model outputs used as input data for NaFRA2.',
          datasets: false,
          children: [{
            name: "simulation_result_set_group",
            label: "Simulation result set group",
            description: 'The simulation result set group is equivalent to a Modelled Flood Group in MapEdit, and represents the set of outputs that are associated with a single model.',
            datasets: true,
            metadata: {
              record: [],
              dataset: [{
                    'name': 'model_origin',
                    'label': 'Model origin',
                    'description': 'Describes whether the model is from MapEdit, new national modelling or other sources',
                    'attribute_type': 'string',
                    'options': [{
                      name: 'mapedit',
                      label: "MapEdit"
                     }, {
                       name: "nnm",
                       label: "New national modelling"
                     }, {
                       name: "llfa",
                       label: "Lead local flood authority"
                     }, {
                       name: "other",
                       label: "Other"
                     }]
                }, {
                    'name': 'map_edit_modelled_flood_group',
                    'label': 'MapEdit modelled flood group',
                    'description': 'If the data has come from MapEdit, the modelled flood group.',
                    'attribute_type': 'integer'
                }, {
                    'name': 'model_software',
                    'label': 'Modelling software',
                    'description': 'The flood modelling software products used to generate the outputs.',
                    'attribute_type': 'string'
                }, {
                    'name': 'model_description',
                    'label': 'Model description',
                    'description': 'Description of the model, from the MapEdit Modelled Flood Group.',
                    'attribute_type': 'string'
                }, {
                    'name': 'model_date',
                    'label': 'Model completion date',
                    'description': 'Description of the model, from the MapEdit Modelled Flood Group.',
                    'attribute_type': 'datetime'
                }, {
                    'name': 'nfcdd_id',
                    'label': 'NFCDD model flood group ID',
                    'description': '',
                    'attribute_type': 'string'
                }, {
                    'name': 'simulation_library_id',
                    'label': 'Simulation library ID',
                    'description': 'Simulation result set group ID',
                    'attribute_type': 'integer'
              }],
              layers: [{
                    'name': 'model_extent',
                    'label': 'Model extent',
                    'description': 'A polygon representing the extent of the model.',
                    'record_level_metadata_attributes': [],
                    'dataset_level_metadata_attributes': [],
                    'metadata_layers': [],
                    'children': []
              }]
            },
            children: [{
              name: "simulation_result_set",
              label: "Simulation result set",
              description: "The simulation result set is equivalent to a Modelled Flood Outline in MapEdit, and represents the available outputs for a given event (i.e. a single event + climate change epoch)",
              datasets: true,
              metadata: {
                record: [],
                dataset: [{
                    'name': 'map_edit_modelled_flood_outline',
                    'label': 'MapEdit modelling flood outline',
                    'description': 'If the data has come from MapEdit, the modelled flood outline.',
                    'attribute_type': 'integer'
                }, {
                    'name': 'modelled_aep',
                    'label': 'Modelled AEP',
                    'description': 'The modelled annual exceedance probability.',
                    'attribute_type': 'number'
                }, {
                    'name': 'modelled_cc',
                    'label': 'Modelled climate change scenario',
                    'description': 'The modelled climate change scenario.',
                    'attribute_type': 'string'
                }, {
                    'name': 'modelled_epoch',
                    'label': 'Modelled epoch',
                    'description': 'The modelled climate change epoch.',
                    'attribute_type': 'string'
                }, {
                    'name': 'global_asset_state',
                    'label': 'Global asset state',
                    'description': 'The modelled global asset state - defended or undefended.',
                    'attribute_type': 'string',
                    'options': [{
                      name: 'defended',
                      label: "Defended"
                    }, {
                      name: 'undefended',
                      label: "Undefended"
                    }]
                }, {
                    'name': 'simulation_library_id',
                    'label': 'Simulation library ID',
                    'description': 'Simulation result set ID',
                    'attribute_type': 'integer'
                }],
                layers: []
              },
              children: [{
                name: "simulation_result",
                label: "Simulation result",
                description: "An individual simulation result - such as a depth grid, flood extent, etc.",
                datasets: true,
                metadata: {
                  record: [],
                  dataset: [{
                      'name': 'hazard_type',
                      'label': 'Hazard type',
                      'description': 'Hazard output type',
                      'attribute_type': 'string',
                      'options': [{
                        name: 'extent',
                        label: "Extent"
                      }, {
                        name: 'depth',
                        label: "Depth"
                      }, {
                        name: 'level',
                        label: "Level"
                      }, {
                        name: 'velocity',
                        label: "Velocity"
                      }, {
                        name: 'flow',
                        label: "Flow"
                      }, {
                        name: 'direction',
                        label: "Direction"
                      }, {
                        name: 'duration',
                        label: "Duration"
                      }, {
                        name: 'hazard',
                        label: "Hazard"
                      }, {
                        name: 'speed_of_onset',
                        label: "Speed of onset"
                      }, {
                        name: 'volume',
                        label: "Volume"
                      }]
                  }, {
                      'name': 'simulation_library_id',
                      'label': 'Simulation library ID',
                      'description': 'Simulation result ID',
                      'attribute_type': 'integer'
                  }],
                  layers: []
                },
                children: []
              }]
            }]
          }]
        }, {
          name: "receptor_input_data",
          label: "Receptor input data",
          description: "Input receptor datasets",
          datasets: false,
          children: [{
            name: "property_input_data",
            label: "Property receptor data",
            description: "",
            children: [{
              name: "property_points",
              label: "Property points",
              description: "Property point data - currently NRD",
              datasets: [{
                name: "nrd",
                label: "National receptor dataset",
                description: "",
                dataset_type: 'spatial_vector',
                metadata_values: {}
              }],
              metadata: {
                record: [{
                  name: 'building_id',
                  label: 'Building ID',
                  description: 'Unique identifier (e.g. TOPO ID) for each property that can be used to join with property thresholds and polygons.',
                  attribute_type: 'number'
                }, {
                  name: 'mcm_code',
                  label: 'MCM code',
                  description: 'Multi-coloured manual property type',
                  attribute_type: 'integer'
                }, {
                  name: 'floor_level',
                  label: 'Floor level',
                  description: 'Flood level designation',
                  attribute_type: 'string'
                }, {
                  name: 'house_type',
                  label: 'House type',
                  description: 'House type',
                  attribute_type: 'string'
                }, {
                  name: 'floor_area',
                  label: 'Floor area',
                  description: 'Floor area',
                  attribute_type: 'number'
                }],
                dataset: [],
                layers: []
              },
              children: []
            }, {
              name: "property_thresholds",
              label: "Property thresholds",
              description: "Property threshold data",
              datasets: true,
              metadata: {
                record: [{
                  name: 'building_id',
                  label: 'Building ID',
                  description: 'Unique identifier (e.g. TOPO ID) for each property that can be used to join with property points and polygons.',
                  attribute_type: 'number'
                }, {
                  name: 'property_threshold',
                  label: 'Property threshold level',
                  description: 'Property threshold level',
                  attribute_type: 'number'
                }, {
                  name: 'source',
                  label: 'Property threshold source',
                  description: 'Property threshold source',
                  attribute_type: 'string'
                }],
                dataset: [],
                layers: []
              },
              children: []
            }, {
              name: "property_polgons",
              label: "Property polygons",
              description: "Property polygon data",
              datasets: true,
              metadata: {
                record: [{
                  name: 'building_id',
                  label: 'Building ID',
                  description: 'Unique identifier (e.g. TOPO ID) for each property that can be used to join with property points and thresholds.',
                  attribute_type: 'number'
                }],
                dataset: [],
                layers: []
              },
              children: []
            }, {
              name: "deprivation",
              label: "Deprivation",
              description: "Deprivation",
              datasets: true,
              metadata: {
                record: [],
                dataset: [],
                layers: []
              },
              children: []
            }]
          }, {
            name: "sites",
            label: "Site and access points",
            description: "Site and access point data",
            datasets: false,
            children: [{
              name: "site_polygons",
              label: "Site polygons",
              description: "Site polygon data",
              datasets: true,
              metadata: {
                record: [],
                dataset: [],
                layers: []
              },
              children: []
            }, {
              name: "access_points",
              label: "Site access points",
              description: "Site access points",
              datasets: true,
              metadata: {
                record: [],
                dataset: [],
                layers: []
              },
              children: []
            }]
          }, {
            name: "agriculture_input_data",
            label: "Agriculture",
            description: "Agriculture datasets",
            datasets: false,
            children: [{
              name: "agricultural_land_classification",
              label: "Agricultural land classification",
              description: "Agricultural land classification",
              datasets: true,
              metadata: {
                record: [{
                  name: 'alc_grade',
                  label: 'ALC grade',
                  description: 'Agricultural land classification grade',
                  attribute_type: 'number'
                }, {
                  name: 'alc_classification',
                  label: 'ALC classification',
                  description: 'Agricultural land classification',
                  attribute_type: 'number'
                }],
                dataset: [],
                layers: []
              },
              children: []
            }]
          }, {
            name: "energy_input_data",
            label: "Energy utilities",
            description: "Energy input data",
            datasets: false,
            children: []
          }, {
            name: "water_input_data",
            label: "Water and wastewater",
            description: "Water and wastewater",
            datasets: false,
            children: []
          }, {
            name: "roads_input_data",
            label: "Roads",
            description: "Roads",
            datasets: false,
            children: []
          }, {
            name: "rail_input_data",
            label: "Rail",
            description: "Rail",
            datasets: false,
            children: []
          }, {
            name: "telecoms_input_data",
            label: "Telecoms",
            description: "Telecoms",
            datasets: false,
            children: []
          }, {
            name: "environmental_input_data",
            label: "Environmental input data",
            description: "Environmental",
            datasets: false,
            children: []
          }]
        }]
      }, {
        name: "derived_data",
        label: "Derived data",
        description: "This entry contains all data produced by NaFRA2",
        datasets: false,
        children: [{
          name: "event_outputs",
          label: "Event-based outputs",
          children: [{
            name: "scenarios",
            label: "Scenarios",
            description: "",
            datasets: true,
            metadata: {
              record: [],
              dataset: [{
                name: 'flood_source',
                label: 'Flood source',
                description: 'Flood source',
                attribute_type: 'string'
              }, {
                name: 'aep',
                label: 'AEP',
                description: 'Annual exceedance probability',
                attribute_type: 'number'
              }, {
                name: 'climate_change_scenario',
                label: 'Climate change scenario',
                description: 'Climate change scenario',
                attribute_type: 'string'
              }, {
                name: 'climate_change_epoch',
                label: 'Climate change epoch',
                description: 'Climate change epoch',
                attribute_type: 'string'
              }, {
                name: 'global_asset_state',
                label: 'Global asset state',
                description: 'Global asset state',
                attribute_type: 'string'
              }],
              layers: []
            },
            children: [{
              name: "hazard_output",
              label: "Hazard outputs",
              description: "Hazard outputs",
              datasets: true,
              metadata: {
                record: [],
                dataset: [{
                    name: "analysis_unit",
                    label: "Analysis unit",
                    description: "Analysis unit",
                    attribute_type: "string"
                  }, {
                    'name': 'hazard_type',
                    'label': 'Hazard type',
                    'description': 'Hazard output type',
                    'attribute_type': 'string',
                    'options': [{
                      name: 'extent',
                      label: "Extent"
                    }, {
                      name: 'depth',
                      label: "Depth"
                    }, {
                      name: 'level',
                      label: "Level"
                    }, {
                      name: 'velocity',
                      label: "Velocity"
                    }, {
                      name: 'flow',
                      label: "Flow"
                    }, {
                      name: 'direction',
                      label: "Direction"
                    }, {
                      name: 'duration',
                      label: "Duration"
                    }, {
                      name: 'hazard',
                      label: "Hazard"
                    }, {
                      name: 'speed_of_onset',
                      label: "Speed of onset"
                    }, {
                      name: 'volume',
                      label: "Volume"
                    }]
                }, {
                  name: "indexing_method",
                  label: "Indexing method",
                  description: "Indexing method",
                  attribute_type: "string"
                }, {
                  name: "indexing_unit",
                  label: "Indexing unit",
                  description: "Indexing unit",
                  attribute_type: "string"
                }, {
                  name: "indexing_value",
                  label: "Indexing value",
                  description: "Indexing value",
                  attribute_type: "number"
                }],
                layers: [{
                    name: 'input_model_metadata',
                    label: 'Input model metadata',
                    description: 'A polygon layer representing the input models used to generate the dataset.',
                    datasets: true,
                    metadata: {
                      record: [{
                          'name': 'model_origin',
                          'label': 'Model origin',
                          'description': 'Describes whether the model is from MapEdit, new national modelling or other sources',
                          'attribute_type': 'string',
                          'options': [{
                            name: 'mapedit',
                            label: "MapEdit"
                          }, {
                            name: "nnm",
                            label: "New national modelling"
                          }, {
                            name: "llfa",
                            label: "Lead local flood authority"
                          }, {
                            name: "other",
                            label: "Other"
                          }]
                      }, {
                          'name': 'map_edit_modelled_flood_group',
                          'label': 'MapEdit modelled flood group',
                          'description': 'If the data has come from MapEdit, the modelled flood group.',
                          'attribute_type': 'integer'
                      }, {
                          'name': 'model_software',
                          'label': 'Modelling software',
                          'description': 'The flood modelling software products used to generate the outputs.',
                          'attribute_type': 'string'
                      }, {
                          'name': 'model_description',
                          'label': 'Model description',
                          'description': 'Description of the model, from the MapEdit Modelled Flood Group.',
                          'attribute_type': 'string'
                      }, {
                          'name': 'model_date',
                          'label': 'Model completion date',
                          'description': 'Description of the model, from the MapEdit Modelled Flood Group.',
                          'attribute_type': 'datetime'
                      }, {
                          'name': 'nfcdd_id',
                          'label': 'NFCDD model flood group ID',
                          'description': '',
                          'attribute_type': 'string'
                      }, {
                          'name': 'map_edit_modelled_flood_outline',
                          'label': 'MapEdit modelling flood outline',
                          'description': 'If the data has come from MapEdit, the modelled flood outline.',
                          'attribute_type': 'integer'
                      }, {
                          'name': 'modelled_aep',
                          'label': 'Modelled AEP',
                          'description': 'The modelled annual exceedance probability.',
                          'attribute_type': 'number'
                      }, {
                          'name': 'modelled_cc',
                          'label': 'Modelled climate change scenario',
                          'description': 'The modelled climate change scenario.',
                          'attribute_type': 'string'
                      }, {
                          'name': 'modelled_epoch',
                          'label': 'Modelled epoch',
                          'description': 'The modelled climate change epoch.',
                          'attribute_type': 'string'
                      }, {
                          'name': 'global_asset_state',
                          'label': 'Global asset state',
                          'description': 'The modelled global asset state - defended or undefended.',
                          'attribute_type': 'string',
                          'options': [{
                            name: 'defended',
                            label: "Defended"
                          }, {
                            name: 'undefended',
                            label: "Undefended"
                          }]
                      }],
                      dataset: [],
                      layers: []
                    },
                    children: []
                }]
              }
            }, {
              name: "impact_output",
              label: "Impact outputs",
              description: "Impact outputs",
              datasets: true,
              metadata: {
                record: [],
                dataset: [{
                  name: 'output_type',
                  label: "Output type",
                  description: "",
                  attribute_type: "string",
                  options: [{
                    name: "tabular",
                    label: "Tabular"
                  }, {
                    name: "vector_point",
                    label: "Point vector"
                  }, {
                    name: "vector_line",
                    label: "Polyline vector"
                  }, {
                    name: "vector_polygon",
                    label: "Polygon vector"
                  }]
                }, {
                  name: 'impact_category',
                  label: 'Impact category',
                  description: '',
                  attribute_type: 'string'
                }],
                layers: []
              },
              children: []
            }]
          }]
        }, {
          name: "integrated_outputs",
          label: "Integrated scenario outputs",
          description: "",
          datasets: false,
          children: [{
            name: "scenarios",
            label: "Scenarios",
            description: "",
            datasets: true,
            metadata: {
              record: [],
              dataset: [{
                name: 'flood_source',
                label: 'Flood source',
                description: 'Flood source',
                attribute_type: 'string',
                list: true
              }, {
                name: 'aep',
                label: 'AEP',
                description: 'Annual exceedance probability',
                attribute_type: 'number',
                list: true
              }, {
                name: 'climate_change_scenario',
                label: 'Climate change scenario',
                description: 'Climate change scenario',
                attribute_type: 'string'
              }, {
                name: 'climate_change_epoch',
                label: 'Climate change epoch',
                description: 'Climate change epoch',
                attribute_type: 'string'
              }, {
                name: 'global_asset_state',
                label: 'Global asset state',
                description: 'Global asset state',
                attribute_type: 'string',
                list: true
              }],
              layers: []
            },
            children: [
              
            ]
          }]
        }]
      }]
    };
    console.log(root);

    var retrieved = localStorage.getItem('nafra2_data');
    if (retrieved != null) {
      var retrieved2 = JSON.parse(retrieved);
      for (var i=0; i<retrieved2.children.length; i++) {
        this.root.children.push(retrieved2.children[i]);
      }
    }
    /*for (var i=0; i<root.children.length; i++) {
      this.root.children.push(root.children[i]);
    }*/
  },
  methods: {
    add: function(ev) {
      this.objParent = ev;
      this.objParentParent = null;
      this.objParent.children.push({
        name: "",
        label: "",
        description: "",
        datasets: false,
        metadata: {
          record: [],
          dataset: [],
          layers: []
        },
        children: []
      });

      this.$set(this, 'obj', this.objParent.children[this.objParent.children.length - 1]);
      
      this.saveCurrent();

    },
    addMetadataLayer: function() {
      this.obj.metadata.layers.push({
        name: "",
        label: "New metadata layer",
        description: "",
        datasets: false,
        metadata: {
          record: [],
          dataset: [],
          layers: []
        },
        children: []
      });
      this.saveCurrent();

    },
    addMetadata: function(type) {
      this.obj.metadata[type].push({
        name: "",
        label: "",
        description: "",
        attribute_type: ""
      });

      this.saveCurrent();
    },
    deleteMetadata: function(type, id) {
      this.obj.metadata[type].splice(id, 1);
      
      this.saveCurrent();
    },
    show: function(ev) {
      console.log(ev);
      this.obj = ev.obj;
      this.objParent = ev.parent;
      this.objParentParent = null;
      this.objParentType = 'children'
    },
    addDataset: function() {
      if (this.obj.datasets == true) {
        this.obj.datasets = [];
      }
      this.obj.datasets.push({
        name: "",
        label: "",
        description: "",
        dataset_type: '',
        metadata_values: {}
      });

      this.saveCurrent();
    },
    deleteDataset: function(id) {
      this.obj.datasets.splice(id, 1);
      if (this.obj.datasets.length == 0) {
        this.obj.datasets = true;
      }
      
      this.saveCurrent();
    },
    deleteEntry: function() {
      var i=0;
      if (this.objParentType == 'children') {
        for (i=0; i<this.objParent.children.length; i++) {
          if (this.objParent.children[i] == this.obj) {
            this.objParent.children.splice(i, 1);
          }
        }
      } else {
        for (i=0; i<this.objParent.metadata.layers.length; i++) {
          if (this.objParent.metadata.layers[i] == this.obj) {
            this.objParent.metadata.layers.splice(i, 1);
          }
        }
      }

      this.saveCurrent();
    },
    handleFile: function(ev) {
      var files = ev.target.files;
      if (files.length > 0) {
        var reader = new FileReader();
        reader.onload = this.onReaderLoad;
        reader.readAsText(files[0]);
        
      }
    },
    onReaderLoad: function(event){
        var obj = JSON.parse(event.target.result);
        console.log(obj);
        
        this.root.children.splice(0);
        for (var i=0; i<obj.children.length; i++) {
          this.root.children.push(obj.children[i]);
        }

        this.saveCurrent();
    },
    saveData: function() {
      var a = document.createElement("a");
      var file = new Blob([JSON.stringify(this.root)], {type: 'text/plain'});
      a.href = URL.createObjectURL(file);
      a.download = 'NaFRA2 data structure.json';
      a.click();
    },
    saveCurrent: function() {
      localStorage.setItem('nafra2_data', JSON.stringify(this.root));
    }
  }
}
</script>

<style lang="scss">

#app, input, textarea, select {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

#app {
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #0A071C;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: stretch;
}

ul.menu {
  flex: 0 0 auto;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E4E3EA;

  li {
    padding: 10px 20px;
    cursor: pointer;

    &:hover {
      background-color: #C8C4CC;
    }

    &.active {
      background-color: #789D00;
      color: white;
    }

    
  }
}

.content, .table {
  flex: 1 0 auto;
  position: relative;
  padding: 10px;
  overflow: auto;
  width: 100%;
  box-sizing: border-box;
}

.table {
  flex: 0 1 auto;
  /*display: flex;
  align-items: stretch;
  justify-content: stretch;
  gap: 10px;*/
  padding-bottom: 30px;

  .column {
    /*flex: 1 1 auto;*/
    border-bottom: 1px solid #E4E3EA;
    padding: 10px;

    &:last-of-type {
      border-width: 0;
    }

    h1, h2, h3, h4, p {
      margin: 0 0 10px 0;
    }
  }

}

table {
  margin-bottom: 10px;

  tr {
    th, td {
      text-align: left;

      h1, h2, h3 {
        margin: 0;
        padding: 10px;
      }

      h1 {
        font-size: 16px;
      }

      h2 {
        font-size: 14px;
      }

      h3 {
        font-size: 12px;
      }

      input, textarea {
        padding: 10px;
        background-color: transparent;
        border-width: 0px;
        box-sizing: border-box;
        width: 100%;

        &.h1 {
          font-size: 16px;
        }

        &.h2 {
          font-size: 14px;
        }

        &.h3 {
          font-size: 12px;
        }
      }
    }
  }

  width: 100%;

  tr:nth-child(odd) {background: #E4E3EA}
  tr:nth-child(even) {background: #FFF}
}

table.dataset {
  tr {
    th {
      padding: 10px;
    }
    td {
      padding: 0px;

      input, textarea, select {
        padding: 10px;
        background-color: transparent;
        border-width: 0px;
        box-sizing: border-box;
        width: 100%;
      }
    }
  }
}

.metadata_layer {
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 10px;
  
  &:hover {
    background-color: #E4E3EA;
  }
}

.icon {
  margin-right: 5px;
}

.button {
  cursor: pointer;

  &:hover {
    color: #789D00;
  }
}

.buttons {
  display: flex;
  gap: 10px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
}

button {
  background-color: #02A1B6;
  color: white;
  border-width: 0;
  padding: 10px;

  &:hover {
    cursor: pointer;
    background-color: #028192;
  }

  &.delete {
    background-color: #E4572E;

    &:hover {
      background-color: #D0451B;
    }
  }
}

</style>
