<template>
  <div class="radial" id="radial">
    <!--<h1>{{ msg }}</h1>
    <p>
      For a guide and recipes on how to configure / customize this project,<br>
      check out the
      <a href="https://cli.vuejs.org" target="_blank" rel="noopener">vue-cli documentation</a>.
    </p>
    <h3>Installed CLI Plugins</h3>
    <ul>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-babel" target="_blank" rel="noopener">babel</a></li>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-eslint" target="_blank" rel="noopener">eslint</a></li>
    </ul>
    <h3>Essential Links</h3>
    <ul>
      <li><a href="https://vuejs.org" target="_blank" rel="noopener">Core Docs</a></li>
      <li><a href="https://forum.vuejs.org" target="_blank" rel="noopener">Forum</a></li>
      <li><a href="https://chat.vuejs.org" target="_blank" rel="noopener">Community Chat</a></li>
      <li><a href="https://twitter.com/vuejs" target="_blank" rel="noopener">Twitter</a></li>
      <li><a href="https://news.vuejs.org" target="_blank" rel="noopener">News</a></li>
    </ul>
    <h3>Ecosystem</h3>
    <ul>
      <li><a href="https://router.vuejs.org" target="_blank" rel="noopener">vue-router</a></li>
      <li><a href="https://vuex.vuejs.org" target="_blank" rel="noopener">vuex</a></li>
      <li><a href="https://github.com/vuejs/vue-devtools#vue-devtools" target="_blank" rel="noopener">vue-devtools</a></li>
      <li><a href="https://vue-loader.vuejs.org" target="_blank" rel="noopener">vue-loader</a></li>
      <li><a href="https://github.com/vuejs/awesome-vue" target="_blank" rel="noopener">awesome-vue</a></li>
    </ul>-->
  </div>
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'RadialTree',
  props: {
    root: Object
  },
  data: function() {
    return {
      svg: undefined,
      width: 1920,
      height: 1080
    }
  },
  methods: {
    drawTree: function() {

      this.svg.selectAll('*').remove();
            
      let input = this.root //our JSON data from the beginning of the article
      let data = d3.hierarchy(input)

      let diameter = this.height * 0.8;
      let radius = diameter / 2;
      let tree = d3.tree()
          .size([2 * Math.PI, radius])
          .separation(function(a, b) { 
              return (a.parent == b.parent ? 1 : 2) / a.depth; 
          });
      
      let treeData = tree(data);
      let nodes = treeData.descendants();
      let links = treeData.links();

      let graphGroup = this.svg.append('g')
      .attr('transform', "translate("+(this.width/2)+","+(this.height/2)+")");

      graphGroup.selectAll(".link")
      .data(links)
      .join("path")
      .attr("class", "link")
      .attr("d", d3.linkRadial()
          .angle(d => d.x)
          .radius(d => d.y));
      
      let node = graphGroup
          .selectAll(".node")
          .data(nodes)
          .join("g")
          .attr("class", "node")
          .attr("transform", function(d){
              return `rotate(${d.x * 180 / Math.PI - 90})` 
                  + `translate(${d.y}, 0)`;
          });
      
      node.append("circle").attr("r", 4);

      node.append("text")
      .attr("font-family", "sans-serif")
      .attr("font-size", function(d) {
        console.log(d);
        return 11 * 2 / 3 + (11/3) * 4 / (d.depth + 1);
      })
      .attr("dx", function(d) { return d.x < Math.PI ? 8 : -8; })
      .attr("dy", ".31em")
      .attr("text-anchor", function(d) { 
          return d.x < Math.PI ? "start" : "end"; 
      })
      .attr("transform", function(d) { 
          return d.x < Math.PI ? null : "rotate(180)"; 
      })
      .text(function(d) { return d.data.label; });
    }
  },
  mounted: function() {
    this.svg = d3.select('#radial')
      .append('svg')
      //.attr('width', this.width)
      //.attr('height', this.height)
      .attr('viewBox', "0 0 1920 1080");
    
    this.drawTree();
  },
  watch: {
    'root': {
      handler: function() {
        this.drawTree();
      },
      deep: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.link {
  fill: none;
  stroke: #ccc;
}

.node {
  circle {
    background-color: #123456;
  }
}

svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
